
// Libraries
import * as React from 'react'
import { useState, useRef } from 'react'
import { graphql, navigate, Link } from 'gatsby'
import moment from 'moment'

// Components
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faFacebookF, faLinkedin, faXTwitter } from '@fortawesome/free-brands-svg-icons'
import { faLink } from '@fortawesome/pro-regular-svg-icons'
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Hero from '../components/hero'
import ReactTooltip from 'react-tooltip'
import DonateCta from '../components/donateCta'
import { GatsbyImage } from 'gatsby-plugin-image'

const BlogTemplate = ({ data }) => {
	const isBrowser = typeof window !== 'undefined'
	const this_url = isBrowser ? window.location.href : process.env.APP_URL
	const winHeight = 520
	const winWidth = 350
	const winTop = isBrowser ? (window.innerHeight / 2) - (winHeight / 2) : ''
	const winLeft = isBrowser ? (window.innerWidth / 2) - (winWidth / 2) : ''

	let copyLinkRef = useRef(null);

	function twitterShare(e){
		e.preventDefault()
		if (!isBrowser) {
			return false
		}
		window.open('http://twitter.com/share?text='+ data.blog.summary +'&url=' + this_url, 'sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width='+winWidth+',height='+winHeight)
	}

	function fbShare(e) {
		e.preventDefault()
		if (!isBrowser) {
			return false
		}
		window.open('http://www.facebook.com/sharer.php?s=100&p[title]=' + data.blog.title + '&p[summary]=' + data.blog.summary + '&p[url]=' + this_url + '&p[images][0]=' + data.blog.image, 'sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width='+winWidth+',height='+winHeight)
	}

	function liShare(e) {
		e.preventDefault()
		if (!isBrowser) {
			return false
		}
		window.open('https://www.linkedin.com/shareArticle?url=' + this_url + '&title=' + data.blog.title + '&summary=' + data.blog.summary, 'sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width='+winWidth+',height='+winHeight)
	}

	const [copyText, setCopyText] = useState('Copy URL to Clipboard');

	function goBack(e){
		e.preventDefault()
		return navigate(-1)
	}

	return (
		<Layout> 			
			<Seo
				title={data.blog.title}
				description={data.blog.summary}
				image={data.blog.image}
				custom={true}
			/>

			<section itemScope itemType="https://schema.org/Blog">
				<Hero contentFullWidth={true} bg="blog" containerClass="container-xl">
					<div className="blog-hero">
						<h1 className="blog-hero__main-heading" itemProp="name">{data.blog.title}</h1>
						<div className="blog-hero__overview">
							<div className="blog-hero__fields">
								{data?.blog?.publish_at &&
									<div className="blog-hero__fields__date" itemProp="startDate" content={moment(data.blog.publish_at).toISOString()}>
										{moment(data.blog.publish_at).format('DD MMMM Y')}
									</div>
								}
							</div>
						</div>
					</div>
					{ data?.blog?.image &&
						<div className="blog-hero__image">
							<a className="blog-go-back" href="/blog" onClick={goBack} target="_blank" rel="noreferrer">
								Back
								<FontAwesomeIcon icon={faArrowLeft} title="Arrow Left icon"/>
								<span className="sr-only"> Back</span>
							</a>
							<div className="blog-hero__image__container">
								<GatsbyImage image={ data.blog.image_gatsby?.childImageSharp.gatsbyImageData }
											 alt={data.blog.title} />
							</div>
						</div>
					}
					<div className="blog-content" dangerouslySetInnerHTML={{__html: data?.blog?.content}}>
					</div>
				</Hero>
				<div className="blog-share">
					<div className="container">
						<div className="share-wrapper">
							<h3>Share this article</h3>
							<ul className="footer-icons">
								<li>
									<a href="https://www.twitter.com" onClick={twitterShare} target="_blank" rel="noreferrer" className="footer-icons__link">
										<FontAwesomeIcon icon={faXTwitter} title="X (Twitter) logo"/>
										<span className="sr-only"> X (Twitter)</span>
									</a>
								</li>
								<li>
									<a href="https://www.facebook.com" onClick={fbShare} target="_blank" rel="noreferrer" className="footer-icons__link">
										<FontAwesomeIcon icon={faFacebookF} title="Facebook logo"/>
										<span className="sr-only"> Facebook</span>
									</a>
								</li>
								<li>
									<a href="https://www.linkedin.com" onClick={liShare} target="_blank" rel="noreferrer" className="footer-icons__link linkedin">
										<FontAwesomeIcon icon={faLinkedin} title="LinkedIn logo"/>
										<span className="sr-only"> LinkedIn</span>
									</a>
								</li>
								<li>
									<Link to={"/blog"} className="footer-icons__link" data-for="tooltip-copy" ref={ref => copyLinkRef = ref} data-tip='tooltip' 
										onClick={(e) => { 
											e.preventDefault();
											navigator.clipboard.writeText(this_url)
											setCopyText("Copied!");
											ReactTooltip.show(copyLinkRef); 
											setTimeout(() => { 
												ReactTooltip.hide(copyLinkRef); 
												setCopyText('Copy URL to Clipboard') 
											}, 3500); 
										}}
									>
										<FontAwesomeIcon icon={faLink} title="Link icon"/>
										<span className="sr-only"> Copy Link URL</span>
									</Link>
									<ReactTooltip id="tooltip-copy" place="top" backgroundColor="#005EB8" textColor="#fff" getContent={() => copyText}>
										<span>{copyText}</span>
									</ReactTooltip>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</section>
			<DonateCta />
		</Layout>
	)
}

//Query GraphQL for an blog with matching apiId to pageContext
export const query = graphql`
	query ($id: String) {
		blog(id: {eq: $id}) {
			id
			slug
			status_id
			title
			summary
			content
			image
			image_gatsby {
				childImageSharp {
					gatsbyImageData(
						width: 2400,
						aspectRatio: 1.78,
						layout: CONSTRAINED,
					)
				}
			}
			publish_at
			created_by
		}
	}
`

export default BlogTemplate
