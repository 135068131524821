// Libraries
import React, {createRef, useState} from 'react'

// Components
import {Link} from 'gatsby'
import {navigate} from 'gatsby'

// Animations
import { Player } from '@lottiefiles/react-lottie-player'
import buttonJson from '../animations/buttonDonate.json'

const DonateCta = () => {
	let buttonLottiePlayer = createRef()
	const [buttonLottie, setButtonLottie] = useState(null)

	const buttonLottieHover = () => {
		buttonLottie.playSegments([[0, 18], [19, 20]], true)
	}

	const buttonLottieBlur = () => {
		buttonLottie.playSegments([[20, 2], [1, 0]], false)
	}

	const buttonLottieClick = () => {
		buttonLottie.playSegments([22, 112], true)
		setTimeout(() => {
			navigate('https://link.justgiving.com/v1/charity/donate/charityId/3135530?currency=GBP')
		}, 3000)
	}

	return (
		<div className='donate'>
			<div className='donate__container'>
				<h2>Help us make an impact. Even a small amount makes a big difference. All Profits are reinvested</h2>
				<button
					target='_blank'
					className='btn btn--lottie-wrap'
					onMouseEnter={buttonLottieHover}
					onMouseLeave={buttonLottieBlur}
					onClick={buttonLottieClick}>
					<span className='sr-only'>Donate</span>
					<Player
						src={buttonJson}
						ref={buttonLottiePlayer}
						lottieRef={(instance) => {
							setButtonLottie(instance)
						}}
						autoplay={false}
						loop={false} />
				</button>
				<p>Or visit our <span><Link to="/donate">Donate Page</Link></span> to find out more</p>
			</div>
		</div>
	)
}

export default DonateCta
