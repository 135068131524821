
// Libraries
import * as React from 'react'
import Classnames from 'classnames'

// Video
import Video from '../video/hero/background-compressed-medium.mp4'

class Hero extends React.Component {
	render() {
		let classes = Classnames([
			'hero',
			{
				'hero--full-height': this.props.fullHeight,
				[`hero--bg-${this.props.bg}`]: this.props.bg,
				'hero--bg-text': this.props.bgText,
				'hero--featured-event': this.props.featuredEvent,
			},
		])

		let contentClasses = Classnames([
			'hero__content',
			{
				'hero__content--full-width': this.props.contentFullWidth
			}
		])

		let containerClasses = Classnames([
			'container',
			{
				[`container-xl`]: this.props.containerClass,
			}
		])

		return (
			<div className={classes} style={{backgroundImage: `url(${this.props.bgImage})`}}>
				<div className={containerClasses}>
					<div className={contentClasses}>
						{this.props.children}
					</div>
				</div>

				{this.props.video ?
					<div className="hero-video">
						<video autoPlay muted loop className="hero-video__video">
							<source src={Video} type="video/mp4"/>
						</video>
					</div>
				: '' }
			</div>
		)
	}
}

export default Hero